import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[5];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  We love so many things about these taco creations from
                  pitmaster Miguel Vidal. Miguel uses a traditional post oak for
                  a unique flavor, which he describes as a "sweet, burnt
                  chocolate brownie aroma" that is layered no the grass-fed
                  brisket and oaxacan sausage. Our all-time favorite was the
                  "Real Deal Holyfield", which is a breakfast taco with fried
                  egg, potatoes, bacon, and smoked brisket. Not far behind is
                  the pulled pollo taco which is covered with delcious tomatillo
                  habanero salsa.
                </p>
                <p>
                  If you hadn't been lucky enough to dive into BBQ Tex Mex, this
                  is our top pick to start your journey. This spot only uses
                  grass-fed beef with no artificial incredients.. 100%
                  authentic. Valentina's Tex Mex BBQ also offers a wide variety
                  of chicken tacos as well, including the scrumptious smoked
                  chicken fajita tacos. So do yourself a favor and visit soon.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
